import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "The Start of Something New",
  "date": "2020-07-12"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi everyone.`}</p>
    <p>{`I'm proud to say that my personal site is now live. My goal is to use this space to house all of my projects and writings that I want to share with the world.`}</p>
    <p>{`Any questions? Comments? Feel free to `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`reach out`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      